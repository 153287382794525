var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-text",
    { staticClass: "black--text" },
    [
      _c("p", [
        _vm._v(
          " This is the first time you are accessing Nuvolos. Please provide some details to create a Nuvolos account associated with your " +
            _vm._s(_vm.isGoogleOauth2 ? "google" : "university") +
            " login: "
        ),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "mt-5",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("v-text-field", {
            attrs: {
              autofocus: !_vm.givenName,
              counter: 50,
              label: "First Name",
              rules: _vm.rules,
              required: "",
              dense: "",
              outlined: "",
            },
            model: {
              value: _vm.givenName,
              callback: function ($$v) {
                _vm.givenName = $$v
              },
              expression: "givenName",
            },
          }),
          _c("v-text-field", {
            attrs: {
              counter: 50,
              label: "Last Name",
              rules: _vm.rules,
              required: "",
              outlined: "",
              dense: "",
            },
            model: {
              value: _vm.familyName,
              callback: function ($$v) {
                _vm.familyName = $$v
              },
              expression: "familyName",
            },
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.$auth.user.email,
              label: "E-mail",
              required: "",
              readonly: "",
              outlined: "",
              dense: "",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                block: "",
                disabled: !_vm.valid,
                loading: _vm.loading,
              },
              on: {
                click: function ($event) {
                  return _vm.createAccount()
                },
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-link-variant"),
              ]),
              _vm._v(" Create Linked Account "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mt-2",
              attrs: { text: "", color: "primary", small: "" },
              on: { click: _vm.showIntercomChat },
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                _vm._v("support"),
              ]),
              _vm._v(" Ask for help "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }